import { registerApplication, start } from "single-spa";

registerApplication({
  name: "@impact-theory/app-menu",
  app: () => System.import("@impact-theory/app-menu"),
  activeWhen: ["/"],
});

registerApplication({
  name: "@impact-theory/app-auth",
  app: () => System.import("@impact-theory/app-auth"),
  activeWhen: ["/user"],
});

registerApplication({
  name: "@impact-theory/app-avatar-arena-admin",
  app: () => System.import("@impact-theory/app-avatar-arena-admin"),
  activeWhen: ["/avatar-arena/create-tournament", "/avatar-arena/tournaments"],
});

registerApplication({
  name: "@impact-theory/app-reports",
  app: () => System.import("@impact-theory/app-reports"),
  activeWhen: ["/reports"],
});

registerApplication({
  name: "@impact-theory/app-all-systems-go",
  app: () => System.import("@impact-theory/app-all-systems-go"),
  activeWhen: ["/all-systems-go"],
});

registerApplication({
  name: "@impact-theory/app-itu-admin",
  app: () => System.import("@impact-theory/app-itu-admin"),
  activeWhen: ["/itu"],
});

registerApplication({
  name: "@impact-theory/data-sync-hubspot",
  app: () => System.import("@impact-theory/data-sync-hubspot"),
  activeWhen: ["/data-sync-hubspot"],
});

start({
  urlRerouteOnly: true,
});
